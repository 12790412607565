<template>
  <v-card flat>
    <loading-bar :value="loading"></loading-bar>
    <v-card-text class="pa-0">
      <v-window v-model="window" touchless>
        <!-- NEW/EDIT -->
        <v-window-item :value="0">
          <v-container fluid>
            <v-row dense>
              <v-col cols="12">
                ADD
              </v-col>
            </v-row>
          </v-container>
        </v-window-item>
        <!-- CURRENT -->
        <v-window-item :value="1">
          <v-card-text v-if="affiliate">
            <v-card>
              <v-toolbar color="color2 color2Text--text">
                <v-toolbar-title>Contacts</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <members
                  :username="affiliate.organization.username"
                  :affiliateId="affiliate.organization.id"
                ></members>
              </v-card-text>
            </v-card>
            <!-- BUTTONS -->
              <v-btn
                color="color3 color3Text--text"
                @click.stop="edit"
                :loading="loading"
                class="mr-2 mb-2"
                v-if="false"
              >
                edit
              </v-btn>
          </v-card-text>
        </v-window-item>
      </v-window>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        v-if="[0, 2].includes(window)"
        color="success"
        :disabled="!saveable"
        @click.stop="onSaveClick(false)"
        :loading="loading"
      >
        {{window === 0 ? 'save' : 'Upload'}}
      </v-btn>
      <v-btn
        v-if="[0, 2].includes(window)"
        color="error"
        @click.stop="cancel"
        :loading="loading"
      >Cancel</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import { firstBy } from 'thenby'
import Members from '@/components/Organization/Members'

export default {
  props: ['affiliate', 'active', 'username'],
  data () {
    return {
      dialog: false,
      window: 0,
      disabled: false,
      title: null,
      file: null,
      comments: null,
      loading: false,
      editing: false,
      updateType: 'Minor',
      required: false
    }
  },
  computed: {
    ...mapGetters(['getPageInfo']),
    owners () {
      return this.affiliate && this.affiliate.members.filter(f => f.role === 'Owner').sort(firstBy('isInvite').thenBy(t => t.user.lastName))
    },
    admins () {
      return this.affiliate && this.affiliate.members.filter(f => f.role === 'Admin').sort(firstBy('isInvite').thenBy(t => t.user.lastName))
    },
    refs () {
      return this.affiliate && this.affiliate.members.filter(f => f.role.startsWith('Score')).sort(firstBy('isInvite').thenBy(t => t.user.lastName))
    },
    contacts () {
      return [
        { title: 'Owner', members: this.owners, show: this.owners && this.owners.length },
        { title: 'Administrators', members: this.admins, show: this.admins && this.admins.length },
        { title: 'Score Keepers', members: this.refs, show: this.refs && this.refs.length }
      ].filter(f => f.show)
    },
    saveable () {
      switch (this.window) {
        case 0:
          return this.agreement ? !!(this.title && (this.title !== this.agreement.title || this.comments !== this.agreement.comments)) : !!(this.title && this.file)
        case 2:
          return !!this.file
        default:
          break
      }
      return false
    },
    organization () {
      return this.getPageInfo(this.username)
    },
    dto () {
      const dto = new FormData()
      dto.append('id', this.agreement ? this.agreement.id : 0)
      dto.append('organizationId', this.organization.id)
      dto.append('title', this.title || this.agreement.title)
      dto.append('updateType', this.updateType)
      dto.append('type', this.agreement ? this.agreement.type : 0)
      this.file && dto.append('files', this.file, this.file.name)
      this.comments && dto.append('comments', this.comments)
      return dto
    }
  },
  methods: {
    edit () {
      this.title = this.agreement.title
      this.comments = this.agreement.comments
      this.window = 0
    },
    revisie () {
      this.title = this.agreement.title
      this.comments = null
      this.window = 2
    },
    init () {
      console.log('init')
      this.window = this.affiliate ? 1 : 0
    },
    onSaveClick (dto) {
      this.loading = true
      this.$VBL.organization.agreements.post(dto || this.dto)
        .then(r => {
          this.$emit('saved', r.data)
          this.window = 1
        })
        .catch(e => { console.log(e.response) })
        .finally(() => { this.loading = false })
    },
    cancel () {
      if (this.agreement) {
        this.window = 1
      } else { this.$emit('cancel-click') }
    },
    toggleRequire () {
      const dto = new FormData()
      dto.append('id', this.agreement.id)
      dto.append('organizationId', this.organization.id)
      dto.append('title', this.agreement.title)
      dto.append('updateType', this.updateType)
      dto.append('comments', this.agreement.comments)
      dto.append('type', this.agreement.type === 0 ? 1 : 0)
      this.onSaveClick(dto)
    }
  },
  watch: {
    agreement: function (val) {
      val && this.init()
    },
    dialog: function (val) {
      if (!val) {
        this.$emit('closed')
        this.window = 1
      }
    },
    active: 'init'
  },
  components: {
    Members
  },
  mounted () {
    this.init()
  }
}
</script>
