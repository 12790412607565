<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="12">
       <affiliates-manager :username="username" :active="true"></affiliates-manager>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AffiliatesManager from '@/components/Affiliates/AffiliatesManager'

export default {
  props: ['username'],
  components: {
    AffiliatesManager
  }
}
</script>

<style>

</style>
