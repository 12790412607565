var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-toolbar',{attrs:{"color":"color1 color1Text--text","flat":""}},[_c('v-toolbar-title',[_c('span',{on:{"click":function($event){$event.stopPropagation();return _vm.onCancelClick($event)}}},[_vm._v("Affiliates")]),(_vm.window === 1)?[_c('v-icon',{staticClass:"mx-3",attrs:{"color":"color1Text"}},[_vm._v("fas fa-angle-right")]),_vm._v(" "+_vm._s(_vm.selectedAffiliate ? _vm.selectedAffiliate.organization.name : 'New')+" ")]:_vm._e()],2),_c('v-spacer'),_c('v-fab-transition',[(!_vm.loading && !_vm.window && false)?_c('v-btn',{attrs:{"color":"color3Text color3--text","fab":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.newAgreement($event)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-plus")])],1):_vm._e()],1)],1),_c('loading-bar',{attrs:{"value":_vm.loading}}),_c('v-window',{attrs:{"touchless":""},model:{value:(_vm.window),callback:function ($$v) {_vm.window=$$v},expression:"window"}},[_c('v-window-item',{attrs:{"value":0}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.affiliates,"options":_vm.pagination,"item-key":"id","multi-sort":"","loading":_vm.loading,"footer-props":{'items-per-page-options': [25, 50, -1]}},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"progress",fn:function(){return [_c('v-progress-linear',{attrs:{"slot":"progress","color":"color3","indeterminate":""},slot:"progress"})]},proxy:true},{key:"item.logo",fn:function(ref){
var item = ref.item;
return [_c('div',{attrs:{"width":"95px"}},[(item.organization.theme && item.organization.theme.logoUrl)?_c('v-img',{style:([207, 198].includes(item.organization.id) ? ("background-color: " + (item.organization.theme.color1)) : ''),attrs:{"height":"40","max-width":"95","src":item.organization.theme.logoUrl,"contain":""}}):_vm._e()],1)]}},{key:"item.fee",fn:function(ref){
var item = ref.item;
return [(item.organizationAmount)?_c('span',[_vm._v(_vm._s(_vm._f("usDollars")(item.organizationAmount)))]):_c('span',[_vm._v(_vm._s(item.organizationPercentage * 100)+"%")])]}},{key:"item.hasStripe",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":item.hasStripe ? 'success' : 'error'}},[_vm._v("fab fa-cc-stripe")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"color3 color3Text--text","fab":"","small":""},on:{"click":function($event){$event.stopPropagation();_vm.selectedAffiliate = item}}},[_c('v-icon',[_vm._v("fas fa-eye")])],1)]}}],null,true)}),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"dense":"","justify":"end"}},[_c('v-btn',{staticClass:"xsmall",attrs:{"color":"color3","text":"","icon":"","loading":_vm.loading},on:{"click":function($event){$event.stopPropagation();return _vm.getAll($event)}}},[_c('v-icon',[_vm._v("fas fa-redo-alt")])],1)],1)],1)],1),_c('v-window-item',{attrs:{"value":1}},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',{staticClass:"pa-0"},[_c('affiliate-details',{ref:"details",attrs:{"affiliate":_vm.selectedAffiliate,"username":_vm.username,"active":_vm.window===1},on:{"cancel-click":_vm.onCancelClick,"saved":_vm.onSaved}})],1),_c('v-card-actions',[_c('v-fab-transition',[_c('v-btn',{attrs:{"color":"color3Text color3--text","fab":""},on:{"click":function($event){$event.stopPropagation();return _vm.onCancelClick($event)}}},[_c('v-icon',[_vm._v("fas fa-caret-left")])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }