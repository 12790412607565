<template>
  <v-card>
    <v-toolbar color="color1 color1Text--text" flat>
      <v-toolbar-title>
        <span @click.stop="onCancelClick">Affiliates</span>
        <template v-if="window === 1">
          <v-icon color="color1Text" class="mx-3">fas fa-angle-right</v-icon> {{selectedAffiliate ? selectedAffiliate.organization.name : 'New'}}
        </template>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-fab-transition>
        <v-btn
          v-if="!loading && !window && false"
          color="color3Text color3--text"
          fab small
          @click.stop="newAgreement"
        >
          <v-icon small>fas fa-plus</v-icon>
        </v-btn>
      </v-fab-transition>
    </v-toolbar>
    <loading-bar :value="loading"></loading-bar>
    <v-window v-model="window" touchless>
      <!-- Affiliate LIST -->
      <v-window-item :value="0">
        <v-data-table
          :headers="headers"
          :items="affiliates"
          :options.sync="pagination"
          item-key="id"
          multi-sort
          :loading="loading"
          :footer-props="{'items-per-page-options': [25, 50, -1]}"
        >
          <template v-slot:progress>
            <v-progress-linear slot="progress" color="color3" indeterminate></v-progress-linear>
          </template>
          <template v-slot:[`item.logo`]="{ item }">
            <div width="95px">
              <v-img
                height="40"
                max-width="95"
                :src="item.organization.theme.logoUrl"
                contain
                v-if="item.organization.theme && item.organization.theme.logoUrl"
                :style="[207, 198].includes(item.organization.id) ? `background-color: ${item.organization.theme.color1}` : ''"
              ></v-img>
            </div>
          </template>
          <template v-slot:[`item.fee`]="{ item }">
            <span v-if="item.organizationAmount">{{item.organizationAmount | usDollars}}</span>
            <span v-else>{{item.organizationPercentage * 100}}%</span>
          </template>
          <template v-slot:[`item.hasStripe`]="{ item }">
            <v-icon :color="item.hasStripe ? 'success' : 'error'">fab fa-cc-stripe</v-icon>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn
              color="color3 color3Text--text"
              @click.stop="selectedAffiliate = item"
              fab small
            >
              <v-icon>fas fa-eye</v-icon>
            </v-btn>
          </template>
        </v-data-table>
        <v-container fluid>
          <v-row dense justify="end">
            <v-btn color="color3" text class="xsmall" icon @click.stop="getAll" :loading="loading">
              <v-icon>fas fa-redo-alt</v-icon>
            </v-btn>
          </v-row>
        </v-container>
      </v-window-item>
      <!-- AGREEMENT DETAILS -->
      <v-window-item :value="1">
        <v-card flat>
          <v-card-text class="pa-0">
            <affiliate-details
              ref="details"
              :affiliate="selectedAffiliate"
              :username="username"
              @cancel-click="onCancelClick"
              @saved="onSaved"
              :active="window===1"
            ></affiliate-details>
          </v-card-text>
          <v-card-actions>
            <v-fab-transition>
              <v-btn
                color="color3Text color3--text"
                fab
                @click.stop="onCancelClick"
              >
                <v-icon>fas fa-caret-left</v-icon>
              </v-btn>
            </v-fab-transition>
          </v-card-actions>
        </v-card>
      </v-window-item>
    </v-window>
  </v-card>
</template>

<script>
import AffiliateDetails from './AffiliateDetails'
import { mapGetters } from 'vuex'

export default {
  props: ['username'],
  data () {
    return {
      window: 0,
      loading: false,
      affiliates: [],
      pagination: { itemsPerPage: 25, sortBy: ['type', 'dtModified'], sortDesc: [true, true] },
      selectedAffiliate: null,
      dialog: false
    }
  },
  computed: {
    ...mapGetters(['getPageInfo']),
    page () {
      return this.getPageInfo(this.username)
    },
    clubs () {
      return this.page.props && this.page.props.includes('affiliate-clubs')
    },
    headers () {
      return [
        { text: 'Id', value: 'id', align: 'left' },
        { text: 'Logo', value: 'logo', align: 'left' },
        { text: 'Affiliate', value: 'organization.name', align: 'left' },
        { text: 'Affiliate Fee', value: 'fee', align: 'center', hide: this.clubs },
        { text: 'Stripe', value: 'hasStripe', align: 'left' },
        { text: 'Active Events', value: 'activeEvents', align: 'center' },
        { text: '', value: 'actions', align: 'center', sortable: false }
      ].filter(f => !f.hide)
    }
  },
  methods: {
    getAll () {
      this.loading = true
      this.$VBL.organization.affiliates.getAll(this.username)
        .then(r => { this.affiliates = r.data })
        .catch(e => console.log(e))
        .finally(() => { this.loading = false })
    },
    newAgreement () {
      this.selectedAffiliate = null
      this.window = 1
    },
    onCancelClick () {
      this.selectedAffiliate = null
      this.window = 0
    },
    onSaved (affiliate) {
      const i = this.affiliates.findIndex(f => f.id === affiliate.id)
      this.selectedAffiliate = affiliate
      if (i < 0) {
        this.affiliates.push(affiliate)
      } else {
        this.affiliates.splice(i, 1, affiliate)
      }
    }
  },
  watch: {
    selectedAffiliate: function (val) {
      if (val) this.window = 1
    }
  },
  mounted () {
    this.getAll()
  },
  components: {
    AffiliateDetails
  }
}
</script>

<style scoped>

</style>
